import * as React from 'react';
import YibberishAppBar from "./components/YibberishAppBar";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import PrivateRoute from "./PrivateRoute";
import ChatPage from "./pages/ChatPage";
import AnonymousRoute from "./AnonymousRoute";
import SignInPage from "./pages/SignInPage";
import YibberishNavFooter from "./components/YibberishNavFooter";
import PartnerConnectPage from "./pages/PartnerConnectPage";
import PartnerPage from "./pages/PartnerPage";
// import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
// import {getToken} from "firebase/messaging";
// import firebaseApp from "./firebase";
// import {doc, getDoc, setDoc} from "firebase/firestore";
// import {useContext, useEffect, useState} from "react";
// import {AuthContext} from "./contexts/AuthContext";
import ActivitiesPage from "./pages/ActivitiesPage";
import BucketListPage from "./pages/BucketListPage";
import {Box} from "@mui/material";

export default function App() {
    // const user = useContext(AuthContext);
    // const [isDialogOpen, setIsDialogOpen] = useState(false);

    // useEffect(() => {
    //     if (!user) {
    //         return;
    //     }
    //
    //     const openDialog = () => setTimeout(() => setIsDialogOpen(true),2000)
    //     const tokenDoc = doc(firebaseApp.firestore, `fcmTokens/${user?.uid}`);
    //     getDoc(tokenDoc).then((tDoc) => {
    //         if (!tDoc.exists()) {
    //             openDialog();
    //         }
    //     });
    //
    // }, [user]);

    return (
        <Router>
            <div>
                <YibberishAppBar />
                {/*<Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>*/}
                {/*    <DialogTitle>For the best experience, enable notifications!</DialogTitle>*/}
                {/*    <DialogActions>*/}
                {/*        <Button size={'small'} onClick={() => {*/}
                {/*            setIsDialogOpen(false);*/}
                {/*            Notification.requestPermission().then((permission) => {*/}
                {/*                console.log(permission);*/}
                {/*                if (permission !== "granted") {*/}
                {/*                    return;*/}
                {/*                }*/}
                {/*                console.log("Notification permission granted. Requesting for token.");*/}
                {/*                getToken(firebaseApp.messaging, {*/}
                {/*                    vapidKey: "BMBd_x_5cJ29UmDmKqh5RQs2VgRom680r_FiTAsxmzdi1WQKHVqG0ENX1eYBPq8j2pivEHe9QB1sNiKtDcVroQI",*/}
                {/*                }).then((token) => {*/}
                {/*                    const tokenDoc = doc(firebaseApp.firestore, `fcmTokens/${user?.uid}`);*/}
                {/*                    setDoc(tokenDoc, {token: token});*/}
                {/*                });*/}
                {/*            });*/}
                {/*        }}>*/}
                {/*            Dismiss*/}
                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}
                <Box sx={{paddingBottom: '64px'}}>
                    <Routes>
                        <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
                        <Route path="/chat" element={<PrivateRoute><ChatPage/></PrivateRoute>}/>
                        <Route path="/partner" element={<PrivateRoute><PartnerPage/></PrivateRoute>}/>
                        <Route path="/partner-connect" element={<PrivateRoute><PartnerConnectPage/></PrivateRoute>}/>
                        <Route path="/activities" element={<PrivateRoute><ActivitiesPage/></PrivateRoute>}/>
                        <Route path="/activities/:activityId" element={<PrivateRoute><ActivitiesPage/></PrivateRoute>}/>
                        <Route path="/bucket-list" element={<PrivateRoute><BucketListPage/></PrivateRoute>}/>
                        <Route path="/" element={<AnonymousRoute><SignInPage/></AnonymousRoute>}/>
                    </Routes>
                </Box>
                <YibberishNavFooter/>
            </div>
        </Router>
  );
}