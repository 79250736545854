import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore';
import {getMessaging} from "firebase/messaging";


// This info is safe to publish
const firebaseConfig = {
    apiKey: "AIzaSyCSlV53LX_od0GsJAskOej6D9lHQNMKftM",
    authDomain: "yibberish-app.firebaseapp.com",
    projectId: "yibberish-app",
    storageBucket: "yibberish-app.appspot.com",
    messagingSenderId: "216331251542",
    appId: "1:216331251542:web:7b965bbb6f7e577c0db0f7",
    measurementId: "G-ZDYDC1NC31"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const cloudFunctions = getFunctions(app)
const auth = getAuth(app)
const defaultStorage = getStorage(app)
const firestore = getFirestore(app);
const messaging = getMessaging(app);

const connectToPartner = httpsCallable(cloudFunctions, 'connectToPartner');
const getPOD = httpsCallable(cloudFunctions, 'getPOD');
const savePODResponse = httpsCallable(cloudFunctions, 'savePODResponse');
const getJenga = httpsCallable(cloudFunctions, 'getJenga');
const getBucketList = httpsCallable(cloudFunctions, 'getBucketList');
const addToBucketList = httpsCallable(cloudFunctions, 'addToBucketList');
const completeBucketListItem = httpsCallable(cloudFunctions, 'completeBucketListItem');
const getNoteSession = httpsCallable(cloudFunctions, 'getNoteSession');
const createNoteSession = httpsCallable(cloudFunctions, 'createNoteSession');
const addNoteToExistingSession = httpsCallable(cloudFunctions, 'addNoteToExistingSession');
const startNoteSession = httpsCallable(cloudFunctions, 'startNoteSession');
const completeNoteInSession = httpsCallable(cloudFunctions, 'completeNoteInSession');

const functions = {
    connectToPartner,
    getPOD,
    getJenga,
    savePODResponse,
    getBucketList,
    addToBucketList,
    completeBucketListItem,
    getNoteSession,
    createNoteSession,
    addNoteToExistingSession,
    startNoteSession,
    completeNoteInSession,
}

const firebaseApp = {
    app,
    analytics,
    auth,
    cloudFunctions,
    defaultStorage,
    functions,
    firestore,
    messaging,
}

export default firebaseApp;