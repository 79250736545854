import React, {useState, useEffect, useContext} from 'react';
import {Box, Button, CircularProgress, Stack, TextField} from '@mui/material';
import firebaseApp from '../../firebase';
import {AuthContext} from "../../contexts/AuthContext";
import usePartnerInfo from "../../hooks/usePartnerInfo";

interface PodActivityProps {

}

const PodActivity: React.FC<PodActivityProps> = (props) => {
    const user = useContext(AuthContext)
    const {partnerId, partnerDisplayName} = usePartnerInfo(true, '/activities');
    const [data, setData] = useState<any>(null);
    const [input, setInput] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            const response = await firebaseApp.functions.getPOD();
            console.log(response.data)
            return response.data
        };

        fetchData().then(data => setData(data));
    }, []);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (data && data.podId && input && user) {
            const podData = await firebaseApp.functions.savePODResponse({podId: data.podId, userResponse: input});
            setInput('');
            setData(podData.data);
        }
    };

    const addToBucketList = async (response: string) => {
        await firebaseApp.functions.addToBucketList(
            {
                title: `POD Prompt: ${data.prompt}`,
                content: response
        });
    };

    if (!data) {
        return <CircularProgress/>
    }
    return (
        <Stack alignItems={'center'}>
            <p>{data.prompt}</p>
            {
                data[user?.uid as string] ?
                    <Box>
                        <p>Your response</p>
                        <p>{data[user?.uid as string]}</p>
                        <Button onClick={() => addToBucketList(data[user?.uid as string])}>Add to Bucket List</Button>
                    </Box>
                    :
                    <form onSubmit={handleSubmit}>
                        <Stack alignItems={'center'}>
                            <TextField
                                variant="outlined"
                                type="text"
                                placeholder="Describe in as much detail as possible"
                                id="message-input"
                                autoComplete="off"
                                required
                                value={input}
                                onChange={(event) => {setInput(event.target.value)}}
                            />
                            <Button type={"submit"} variant={'contained'}>Submit</Button>
                        </Stack>
                    </form>
            }
            {
                data[partnerId as string] ?
                    data[user?.uid as string] ?
                        <Box>
                            <p>{partnerDisplayName}'s response</p>
                            <p>{data[partnerId as string]}</p>
                            <Button onClick={() => addToBucketList(data[partnerId as string])}>Add to Bucket List</Button>
                        </Box>
                        :
                        <Box>
                            <p>{partnerDisplayName} has responded. Submit your answer to see their response</p>
                        </Box>
                    :
                    <Box>
                        <p>
                            {partnerDisplayName} hasn't responded yet.
                        </p>
                    </Box>
            }
        </Stack>
    );
};

export default PodActivity;