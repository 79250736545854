/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import {Stack, Typography} from "@mui/material";
import {useEffect} from "react";

const labels: { [index: string]: string } = {
    0.5: 'Predictable',
    1: 'Meh',
    1.5: 'Lame',
    2: 'Hmmm',
    2.5: 'Listening...',
    3: 'Nice!',
    3.5: 'Getting hot!',
    4: 'Mmm, baby',
    4.5: 'You sure?',
    5: "Let's screw already",
};

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export interface HoverRatingProps {
    onValueUpdate: (value: number) => void;
}

export default function HoverRating(props: HoverRatingProps) {
    const [value, setValue] = React.useState<number>(2);
    const [hover, setHover] = React.useState(-1);

    useEffect(() => {
        props.onValueUpdate(value)
    }, [value])

    return (
        <Box
            sx={{
                width: 200,
                display: 'flex',
                alignItems: 'center',
                marginY: 2,
            }}
        >
            <Stack>
                <Typography component="legend">Randomness</Typography>
                <Box display={'flex'}>
                    <Rating
                        name="hover-feedback"
                        value={value}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                            setValue(newValue || 0);
                        }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                        icon={<WhatshotIcon fontSize="inherit" />}
                        emptyIcon={<WhatshotOutlinedIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                    {value !== null && (
                        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                    )}
                </Box>

            </Stack>
        </Box>
    );
}
