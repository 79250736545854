import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, FormControl,
    Grid, InputLabel, MenuItem, Select,
} from '@mui/material';
import firebaseApp from '../../firebase';
import usePartnerInfo from "../../hooks/usePartnerInfo";
import HoverRating from "../HoverRating";

interface JengaActivityProps {

}

const JengaActivity: React.FC<JengaActivityProps> = () => {
    usePartnerInfo(true, '/activities');
    const [randomnessValue, setRandomnessValue] = useState<number>(0);
    const [level, setLevel] = useState<number>(0);
    const [data, setData] = useState<any>(null);
    const [open, setOpen] = React.useState(false);
    const [selectedChallenge, setSelectedChallenge] = useState<number>(1);

    const handleClickOpen = (challengeIdx: number) => {
        setSelectedChallenge(challengeIdx);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await firebaseApp.functions.getJenga();
            console.dir(response.data)
            return response.data
        };

        // @ts-ignore
        fetchData().then(data => setData(data));
    }, []);

    const getDialogRevealText = () => {

        let randomValue = Math.random();
        let randomValue2 = Math.random();
        let challenge = "";

        if (randomValue + (.25 * (randomnessValue / 5)) < 1) {
            challenge = data[selectedChallenge][level];
            console.log(challenge)
            return challenge;
        }

        const newLevel = level + 1;
        if (newLevel > data[selectedChallenge].length || randomValue2 + (.25 * (randomnessValue / 5)) >= 1) {
            challenge = data.alternateChallenges[Math.floor(randomValue2 * data.alternateChallenges.length)];
            challenge += " (Alternate challenge)";
            console.log(challenge)
            return challenge;
        }

        challenge = data[selectedChallenge][newLevel];
        challenge += " (Next level challenge)";
        console.log(challenge)
        return challenge;
    }

    if (!data) {
        return <CircularProgress/>
    }
    return (
        <Box>
            <HoverRating onValueUpdate={(val: number) => setRandomnessValue(val)}/>
            <FormControl fullWidth sx={{marginBottom: 2}}>
                <InputLabel id="demo-simple-select-label">Level</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={level}
                    label="Level"
                    onChange={(e) => setLevel(e.target.value as number)}
                >
                    <MenuItem value={0}>Warming up...</MenuItem>
                    <MenuItem value={1}>Getting spicy</MenuItem>
                    <MenuItem value={2}>Knock it down already!</MenuItem>
                </Select>
            </FormControl>
            <Box>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {Object.keys(data).map((key: any) => {
                        return (
                            <Grid item xs={6}>
                                <Card variant={'outlined'}>
                                    <CardContent>
                                        {key}
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" onClick={() => handleClickOpen(key)}>Reveal</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            {data && <Dialog open={open}>
                <DialogContent>
                    <DialogContentText>
                        {getDialogRevealText()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>}
        </Box>
    );
};

export default JengaActivity;