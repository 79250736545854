import {PropsWithChildren, useEffect, useState} from "react";
import { AuthContext } from "../AuthContext";
import firebase from "firebase/auth";
import firebaseApp from "../../firebase";
import React from "react";
import { user } from 'rxfire/auth';


export const AuthProvider: React.FC<PropsWithChildren<any>> = ( props: PropsWithChildren<any> ) => {
    const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);

    useEffect(() => {
            user(firebaseApp.auth).subscribe(u => { setCurrentUser(u) } );
        }, []);

    return <AuthContext.Provider value={currentUser}>{props.children}</AuthContext.Provider>;
};