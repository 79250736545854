import React, {useState, useEffect, useContext} from 'react';
import firebaseApp from "../../firebase";
import {Button, Grid, Stack, TextField} from "@mui/material";
import {AuthContext} from "../../contexts/AuthContext";


interface NoteActivityProps {

}

export interface Note {
    id?: string | null;
    text: string;
    creatorId: string;
    completedOn: Date | null;
    isFavorite: boolean;
    isActive: boolean;
}

export interface NoteSession {
    startsOn: Date | null;
    numDaysDuration: number;
    randomnessSetting: number;
    notes: Note[];
    completedNoteIds: string[];
    activeNoteId: string | null;
    activeNoteAssignedOn: Date | null;
    numPromptedNotesPerParticipant: number;
    numNotesToCompletePerParticipant: number;
}

const NoteActivity: React.FC<NoteActivityProps> = () => {
    const user = useContext(AuthContext);
    const [noteSession, setNoteSession] = useState<NoteSession | null | undefined>(undefined);
    const [noteText, setNoteText] = useState('');

    useEffect(() => {
        updateNoteSession()
    }, []);

    const updateNoteSession = async () => {
        const fetchNoteSession = async () => {
            const response = await firebaseApp.functions.getNoteSession();
            console.dir(response.data)
            return response.data as NoteSession | null;
        };

        fetchNoteSession().then(data => setNoteSession(data));
    }

    const handleNoteSubmit = async () => {
        console.log("Submitting note")
        if (noteSession!.notes.filter((n) => n.creatorId === user?.uid).length < noteSession!.numPromptedNotesPerParticipant) {
            const newNote: Note = {
                creatorId: user!.uid,
                text: noteText,
                completedOn: null,
                isFavorite: false,
                isActive: false,
            };
            await firebaseApp.functions.addNoteToExistingSession({note: newNote});
            setNoteText('');
            await updateNoteSession()
        }
    };

    const handleNoteCompletion = async (noteId: string) => {
          await firebaseApp.functions.completeNoteInSession({noteId})
    };

    function renderNoExistingNoteActivity() {
        return (
            <div>
                <p>You do not have an active note activity.</p>
                <Button variant="contained" color="primary" onClick={async () => {
                    const noteSession = await firebaseApp.functions.createNoteSession();
                    setNoteSession(noteSession.data as NoteSession | null);
                }}>
                    Create New Note Activity
                </Button>
            </div>
        );
    }

    function renderPendingNoteActivity() {
        return (
            <div>
                <p>You have a pending note activity.</p>
                <p>Number of notes to complete: {noteSession?.numNotesToCompletePerParticipant}</p>
                <p>Number of notes prompted: {noteSession?.numPromptedNotesPerParticipant}</p>
                <Stack>
                    <h4>Your notes</h4>
                    <Grid container spacing={2}>
                        {noteSession?.notes.filter((n) => n.creatorId === user?.uid).map(note => (
                            <Grid item key={note.id}>
                                <p>{note.text}</p>
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
                {noteSession!.notes.filter((n) => n.creatorId === user?.uid).length < noteSession!.numPromptedNotesPerParticipant ? (
                    <>
                        <TextField
                            value={noteText}
                            onChange={(e) => setNoteText(e.target.value)}
                            label="Note"
                            variant="outlined"
                        />
                        <Button variant="contained" color="primary" onClick={handleNoteSubmit}>
                            Add Note
                        </Button>
                    </>
                ) : (
                    <>
                        {noteSession?.notes.filter((n) => n?.creatorId !== user?.uid).length !== noteSession?.numPromptedNotesPerParticipant ?
                            <p>Waiting for partner to finish prompts</p>
                            :
                            <Button variant="contained" color="primary" onClick={async () => {
                                const response = await firebaseApp.functions.startNoteSession();
                                setNoteSession(response.data as NoteSession);
                            }}>
                                Start Activity
                            </Button>
                        }
                    </>
                )}
            </div>
        );
    }

    function renderActiveNoteActivity() {
        return (
            <div>
                <p>You have an active note activity.</p>
                <p>Duration: {noteSession?.numDaysDuration} days</p>
                <p>Number of notes to complete: {noteSession?.numNotesToCompletePerParticipant}</p>
                <p>Number of notes prompted: {noteSession?.numPromptedNotesPerParticipant}</p>
                <p>Randomness setting: {noteSession?.randomnessSetting}</p>
                <p>Your active notes:</p>
                <ul>
                    {noteSession?.notes.filter((n) => n.creatorId !== user?.uid && n.isActive && !n.completedOn).map(note => (
                            <li key={note.id}>
                                <Stack>
                                    <p>{note.text}</p>
                                    <Button variant="contained" color="primary" onClick={() => handleNoteCompletion(note.id as string)}>Complete Note</Button>
                                </Stack>
                            </li>
                    ))}
                </ul>
            </div>
        );
    }

    if (noteSession === undefined) {
        return <p>Loading...</p>;
    }

    if (noteSession === null) {
        return renderNoExistingNoteActivity();
    }

    if (!noteSession.startsOn) {
        return renderPendingNoteActivity();
    }

    return renderActiveNoteActivity();
};

export default NoteActivity;