import Container from "@mui/material/Container";
import {TextField, Card, CardContent, Typography, Grid, InputAdornment, useTheme} from "@mui/material";
import * as React from "react";
import {useContext, useState, useEffect, useRef} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {collection, addDoc, serverTimestamp, onSnapshot, query, orderBy, limit} from "firebase/firestore";
import firebaseApp from "../firebase";
import usePartnerInfo from "../hooks/usePartnerInfo";
import {getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { IconButton } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowDownUp from '@mui/icons-material/KeyboardArrowUp';
import Image from "mui-image";

interface MessageCardProps {
    message: any,
    isUserMessage: boolean,
    displayName: string,
}

const MessageCard = (props: MessageCardProps) => {
    const theme = useTheme();
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (props.message.imageUrl) {
            const storage = firebaseApp.defaultStorage;
            const imageRef = ref(storage, props.message.imageUrl);
            getDownloadURL(imageRef).then(setImageUrl);
        }
    }, [props.message.imageUrl]);

    const handleImageClick = (event: any) => {
        setIsExpanded(prev => !prev);
    };

    return (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: props.isUserMessage ? 'flex-end' : 'flex-start' }}>
            <Card sx={{ maxWidth: '100%', bgcolor: props.isUserMessage ? 'primary.main' : 'secondary.main', color: props.isUserMessage ? 'primary.contrastText' : 'secondary.contrastText' }}>
                <CardContent>
                    <Typography variant="subtitle2">{props.displayName}</Typography>
                    {imageUrl && (
                        <>
                            <IconButton
                                onClick={handleImageClick}
                                sx={{ color: theme.palette.primary.contrastText }}
                            >
                                {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowDownUp />}
                            </IconButton>
                            {isExpanded &&
                                <Image
                                    src={imageUrl}
                                    alt="Message attachment"
                                    style={{height: 'auto', cursor: 'pointer'}}
                                />}
                        </>
                    )}
                    <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>{props.message.text}</Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};

interface MessageInputProps {
    onSendMessage: (message: string, imageUrl: string | null) => void;
    partnershipId: string;
}

const MessageInput = (props: MessageInputProps) => {
    const [newMessage, setNewMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleFileButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSendMessage = async (event: React.FormEvent<HTMLFormElement> | null) => {
        event?.preventDefault();
        if (newMessage.trim() !== '' || selectedFile) {
            let imageUrl = null;
            if (selectedFile) {
                imageUrl = `images/${props.partnershipId}/${selectedFile.name}`
                const storage = firebaseApp.defaultStorage;
                const storageRef = ref(storage, imageUrl);
                await uploadBytesResumable(storageRef, selectedFile);
            }
            props.onSendMessage(newMessage, imageUrl);
            setNewMessage('');
            setSelectedFile(null);
            setPreviewUrl(null);
        }
    };

    return (
        <form onSubmit={handleSendMessage}>
            <TextField
                variant="outlined"
                fullWidth
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                sx={{ paddingX: 0 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="end">
                            <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleFileButtonClick}>
                                <AttachFileIcon />
                            </IconButton>
                            {previewUrl && <Image src={previewUrl} alt="Preview" style={{ width: '50px', height: 'auto' }} />}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton color="primary" aria-label="send message" component="span" onClick={() => {
                                handleSendMessage(null)
                            }}>
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <input type="file" hidden ref={fileInputRef} onChange={handleFileChange} />
        </form>
    );
};

const ChatPage = () => {
    const user = useContext(AuthContext);
    const {partnerId, partnerDisplayName, selfDisplayName, partnershipId} = usePartnerInfo(true, '/chat');
    const [messages, setMessages] = useState<any | null>([]);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (!user || !partnerId || !partnershipId) {
            return;
        }
        const db = firebaseApp.firestore;

        const messagesQuery = query(collection(db, `partnerships/${partnershipId}/messages`), orderBy("timestamp", "desc"), limit(10));
        const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
            setMessages(snapshot.docs.map(doc => doc.data()).reverse());
        });

        return unsubscribe;

    }, [partnerId, partnershipId, user]);

    useEffect(() => {
        if (messagesEndRef.current) {
            // @ts-ignore
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSendMessage = async (message: any, imageUrl: string | null) => {
        if (!user) {
            return;
        }
        const db = firebaseApp.firestore;

        await addDoc(collection(db, `partnerships/${partnershipId}/messages`), {
            text: message,
            timestamp: serverTimestamp(),
            sender: user.uid,
            recipient: partnerId,
            imageUrl: imageUrl,
        });
    };

    return (
        <Container maxWidth="sm" sx={{maxHeight: '80vh', marginBottom: 2}}>
            <Grid container spacing={2} sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                maxWidth: '100%',
                overflowY: 'auto',
                maxHeight: '70vh',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                paddingY: 1,
            }}>
                {messages.map((message: any, index: number) => (
                    <MessageCard
                        key={index}
                        message={message}
                        isUserMessage={message.sender === user?.uid}
                        displayName={message.sender === user?.uid ? selfDisplayName ?? 'You' : partnerDisplayName ?? 'partner'}
                    />
                ))}
                <div ref={messagesEndRef} />
            </Grid>
            {partnershipId && <MessageInput onSendMessage={handleSendMessage} partnershipId={partnershipId}/>}
        </Container>
    )
}

export default ChatPage;