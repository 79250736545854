/* eslint-disable react-hooks/exhaustive-deps */
import Container from "@mui/material/Container";
import * as React from "react";
import {Box, Divider, Stack, Typography} from "@mui/material";

const HomePage = () => {

    function renderComingSoon() {
        return (
            <Box>
                <Typography>
                    Coming Soon!
                </Typography>
            </Box>
        )
    }

    return (
        <Container maxWidth="sm">
            <Stack alignItems={"center"} sx={{ my: 4 }}>
                { renderComingSoon() }
                <Divider/>
            </Stack>
        </Container>
    )
}

export default HomePage;