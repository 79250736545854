import Container from "@mui/material/Container";
import * as React from "react";
import {Button, Stack, TextField, Typography} from "@mui/material";
import {useContext, useState} from "react";
import firebaseApp from "../firebase";
import {AuthContext} from "../contexts/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";

const PartnerConnectPage = () => {
    const user = useContext(AuthContext);
    const [ partnerUserId, setPartnerUserId ] = useState('');
    const [ displayName, setDisplayName ] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const connectPartner = async () => {
        await firebaseApp.functions.connectToPartner({partnerUserId: partnerUserId, displayName: displayName});
        await user?.getIdTokenResult(true)

        const params = new URLSearchParams(location.search);
        const redirect = params.get('redirect');

        navigate(redirect ?? '/partner');
    }

    return (
        <Container maxWidth="sm">
            <Stack>
                <Typography variant={"h6"}>Your id is: {user?.uid}</Typography>
                <TextField
                    label="Your Display Name"
                    variant="outlined"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                />
                <TextField
                    label="Partner User ID"
                    variant="outlined"
                    value={partnerUserId}
                    onChange={(e) => setPartnerUserId(e.target.value)}
                />
                <Button variant={"contained"} onClick={connectPartner}>
                    Connect with Partner
                </Button>
            </Stack>
        </Container>
    )
}

export default PartnerConnectPage;