import Container from "@mui/material/Container";
import * as React from "react";
import {Stack, Typography} from "@mui/material";
import {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";
import usePartnerInfo from "../hooks/usePartnerInfo";

const PartnerPage = () => {
    const user = useContext(AuthContext);
    const {partnerId} = usePartnerInfo(true, '/partner');

    return (
        <Container maxWidth="sm">
            <Stack>
                <Typography variant={"h6"}>Your id is: {user?.uid}</Typography>
                <Typography variant={"h6"}>Your partner id is: {partnerId}</Typography>
            </Stack>
        </Container>
    )
}

export default PartnerPage;