import {PropsWithChildren, useContext} from "react";
import { AuthContext } from "./contexts/AuthContext";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const AnonymousRoute = ( props: PropsWithChildren<any> ) => {
    const user = useContext(AuthContext);

    if (!user) {
        return props.children;
    }

    return <Navigate to="/home" />;
};

AnonymousRoute.propTypes = {
    children: PropTypes.node,
};

export default AnonymousRoute;