import React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import {Link} from 'react-router-dom';

export interface Activity {
    id: string;
    name: string;
    renderIcon: () => React.ElementType<any, any>;
}

interface ActivityTileProps {
    activity: Activity;
}

const ActivityTile: React.FC<ActivityTileProps> = (props: ActivityTileProps) => {
    return (
        <Card>
            <CardActionArea component={Link} to={`/activities/${props.activity.id}`}>
                <CardContent>
                    <>
                        {props.activity.renderIcon()}
                        <Typography variant="h5" component="div">
                            {props.activity.name}
                        </Typography>
                    </>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ActivityTile;