/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {useNavigate} from "react-router-dom";
import firebaseApp from "../firebase";
import {doc, getDoc} from "firebase/firestore";

const usePartnerInfo = (shouldRedirectOnFail: boolean, redirectBack: string) => {
    const [partnerId, setPartnerId] = useState<string | null>(null);
    const [partnerDisplayName, setPartnerDisplayName] = useState<string | null>(null);
    const [selfDisplayName, setSelfDisplayName] = useState<string | null>(null);
    const [partnershipId, setPartnershipId] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();

        const redirectOnFail = () => {
            if (shouldRedirectOnFail) {
                navigate('/partner-connect?redirect=' + redirectBack);
            }
        }

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                user.getIdTokenResult().then((idTokenResult) => {
                    const partnerId = idTokenResult.claims?.partnerUserId;
                    console.log(partnerId)
                    if (!partnerId) {
                        redirectOnFail();
                        return;
                    }
                    // @ts-ignore
                    setPartnerId(partnerId);
                    const localPartnershipId = [user.uid, partnerId].sort().join('_');
                    setPartnershipId(localPartnershipId);

                    const docRef = doc(firebaseApp.firestore, `partnerships/${localPartnershipId}`)
                    if (docRef) {
                        getDoc(docRef).then((doc) => {
                            if (doc.exists()) {
                                setPartnerDisplayName(doc.data()[`${partnerId}-displayName`]);
                                setSelfDisplayName(doc.data()[`${user.uid}-displayName`]);
                            }
                        });
                    }
                });
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    return {partnerId, partnershipId, partnerDisplayName, selfDisplayName};
};

export default usePartnerInfo;