import { createTheme } from '@mui/material/styles';
import {darken, lighten} from "@mui/material";


const baseTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#c6b9b8',
        },
        secondary: {
            main: '#8e71ae',
        },
        info: {
            main: '#758294',
        },
        error: {
            main: '#C11717',
        },
        warning: {
            main: '#FF780A',
        },
        success: {
            main: '#139C1A',
        },
    },
});


const theme = createTheme(baseTheme, {
    palette: {
        background: {
            paper: lighten(baseTheme.palette.primary.main, 0.98),
            default: lighten(baseTheme.palette.primary.main, 0.95),
        },
        divider: darken(baseTheme.palette.primary.main, 0.65),
        text: {
            primary: darken(baseTheme.palette.primary.main, 0.55),
            secondary: darken(baseTheme.palette.primary.main, 0.65),
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export default theme;