/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Container from "@mui/material/Container";
import {useEffect, useState} from "react";
import PodActivity from "../components/activities/PodActivity";
import {useParams} from "react-router-dom";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AllActivities from "../components/activities/AllActivities";
import JengaActivity from "../components/activities/JengaActivity";
import NoteActivity from "../components/activities/NoteActivity";


const ActivitiesPage = () => {
    enum Activities {
        NONE,
        POD,
        JENGA,
        NOTES,
    }

    const convertActivityIdToEnum = (activityId: string | undefined): Activities => {
        if (!activityId) {
            return Activities.NONE;
        }
        return parseInt(activityId) as Activities;
    }

    const { activityId } = useParams<{ activityId: string }>();

    const [selectedActivity, setSelectedActivity] = useState<Activities>(convertActivityIdToEnum(activityId));

    useEffect(() => {
        setSelectedActivity(convertActivityIdToEnum(activityId));
    }, [activityId])

    function renderActivity() {
        switch (selectedActivity) {
            case Activities.NONE:
                return <AllActivities/>
            case Activities.POD:
                return <PodActivity />
            case Activities.JENGA:
                return <JengaActivity />
            case Activities.NOTES:
                return <NoteActivity />
        }
    }

    const renderBackToActivities = () => (
        <Box display="flex" alignItems="center">
            <IconButton href="/activities" color="secondary">
                <KeyboardBackspaceIcon />
                <Typography variant="h6">
                    All Activities
                </Typography>
            </IconButton>
        </Box>
    );

    return (
        <Container>
            <Stack>
                <>
                    {selectedActivity !== Activities.NONE && renderBackToActivities()}
                    {renderActivity()}
                </>
            </Stack>
        </Container>
    )
}

export default ActivitiesPage;