/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import ChatIcon from '@mui/icons-material/Chat';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import {
    BottomNavigation,
    BottomNavigationAction,
    Paper,
} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import usePartnerInfo from "../hooks/usePartnerInfo";

export default function YibberishNavFooter() {
    const {partnerId} = usePartnerInfo(false, '');
    const routes = ['/home', '/chat', '/partner', '/activities', '/bucket-list']

    const { pathname } = useLocation();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (pathname.includes('/activities')) {
            setValue(3);
            return;
        }

        setValue(routes.indexOf(pathname))
    }, [pathname]);

    return (
        <Paper
            sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
            elevation={3}
        >
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction label="Home"            to="/home"            component={Link} icon={value === 0 ? <HomeIcon color={'secondary'}/> : <HomeOutlinedIcon color={'primary'}/>} />
                <BottomNavigationAction label="Chat"   to="/chat"   component={Link} icon={value === 1 ? <ChatIcon color={'secondary'}/> : <ChatOutlinedIcon color={'primary'}/>} />
                {!partnerId && <BottomNavigationAction label="Partner" to="/partner" component={Link}
                                         icon={value === 2 ? <PeopleIcon color={'secondary'}/> :
                                             <PeopleOutlinedIcon color={'primary'}/>}/>}
                <BottomNavigationAction label="Activities"   to="/activities"   component={Link} icon={value === 3 ? <SportsEsportsIcon color={'secondary'}/> : <SportsEsportsOutlinedIcon color={'primary'}/>} />
                <BottomNavigationAction label="Bucket List"   to="/bucket-list"   component={Link} icon={value === 4 ? <ChecklistIcon color={'secondary'}/> : <ChecklistOutlinedIcon color={'primary'}/>} />
            </BottomNavigation>
        </Paper>
    );
}