import Container from "@mui/material/Container";
import * as React from "react";
import SignInCard from "../components/SignInCard";
import {Stack} from "@mui/material";

const SignInPage = () => {

    return (
        <Container maxWidth="sm">
            <Stack alignItems={"center"} sx={{ my: 4 }}>
                <SignInCard/>
            </Stack>
        </Container>
    )
}

export default SignInPage;