import React from 'react';
import { Box } from '@mui/material';
import ActivityTile, {Activity} from "./ActivityTile";
import CelebrationIcon from '@mui/icons-material/Celebration';


interface AllActivitiesProps {

}

const AllActivities: React.FC<AllActivitiesProps> = () => {

    const allActivities: Activity[] = [
        {
            id: '1',
            name: 'Pod Activity',
            renderIcon: () => <CelebrationIcon />
        },
        {
            id: '2',
            name: 'Jenga',
            renderIcon: () => <CelebrationIcon />
        },
        {
            id: '3',
            name: 'Notes',
            renderIcon: () => <CelebrationIcon />
        },
    ];


    return (
        <Box sx={{ overflow: 'auto', maxHeight: '100vh' }}>
            {allActivities.map((activity) => (
                <Box key={activity.id} >
                    <ActivityTile activity={activity} />
                </Box>
            ))}
        </Box>
    );
};

export default AllActivities;