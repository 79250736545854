// PrivateRoute.js
import {PropsWithChildren, useContext, useEffect, useState} from "react";
import { AuthContext } from "./contexts/AuthContext";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import firebaseApp from "./firebase";

const PrivateRoute = ( props: PropsWithChildren<any> ) => {
    const user = useContext(AuthContext);
    const [isUserReady, setIsUserReady] = useState<boolean>(false);

    useEffect(() => {
        firebaseApp.auth.authStateReady().then(() => {
            setIsUserReady(true);
        })
    }, [])

    if (user || !isUserReady) {
        return props.children;
    }

    return <Navigate to="/" />;
};

PrivateRoute.propTypes = {
    children: PropTypes.node,
};

export default PrivateRoute;