import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, ListItem, ListItemText, List } from '@mui/material';
import firebaseApp from '../firebase';
import { AuthContext } from '../contexts/AuthContext';

const BucketListPage = () => {
    const user = useContext(AuthContext);
    const [bucketListItems, setBucketListItems] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const items = await firebaseApp.functions.getBucketList();
            return items.data as any[];
        };

        fetchData().then(items => setBucketListItems(items))
    }, [user]);

    const handleItemClick = async (itemId: string) => {
        await firebaseApp.functions.completeBucketListItem({itemId});
        // Refresh the bucket list items after one is completed
        const items = await firebaseApp.functions.getBucketList();
        setBucketListItems(items.data as any[]);
    };

    return (
        <List>
            {bucketListItems.map((item, index) => (
                <ListItem key={index} dense button onClick={() => handleItemClick(item.id)}>
                    <Checkbox
                        edge="start"
                        checked={item.completedOn !== null}
                        tabIndex={-1}
                        disableRipple
                    />
                    <ListItemText primary={item.title} secondary={item.content} />
                </ListItem>
            ))}
        </List>
    );
};

export default BucketListPage;